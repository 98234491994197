<template>
  <section class="h-100 W-100 background-white">
    <div class="p-5" style="height: 100vh;">
      <section class="invoice-add-wrapper">
        <b-row class="invoice-add">
          <b-col cols="12" xl="12">
            <b-form>
              <validation-observer ref="formDataValidation">
                <b-card no-body class="invoice-preview-card">
                  <b-card-body class="invoice-padding pt-3">
                    <b-row>
                      <b-col cols="12 mb-2">
                        <div class="w-100 d-lg-flex align-items-center justify-content-center pt-1">
                          <b-img
                            fluid
                            :src="sideImg"
                            alt="Login V2"
                            style="width: 50%;"
                          />
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2">
                          Tipo de persona:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="Tipo de persona"
                          rules="required"
                        >
                          <b-form-group label-for="type-people" :class="errors.length > 0 ? 'is-invalid' : null">
                            <v-select
                              id="type-people"
                              v-model="formData.typePerson"
                              :options="typePeopleOptions"
                              :reduce="val => val.id"
                              input-id="proceeding-type"
                              label="type"
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2">
                          Regimen tributario:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="regimen tributario"
                          rules="required"
                        >
                          <b-form-group label-for="tax-regime" :class="errors.length > 0 ? 'is-invalid' : null">
                            <v-select
                              id="tax-regime"
                              v-model="formData.taxRegime"
                              :options="taxRegimesOptions"
                              :reduce="val => val.id"
                              input-id="tax-regime"
                              label="name"
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2">
                          Tipo de identificación:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="tipo de identificación"
                          rules="required"
                        >
                          <b-form-group label-for="type-document" :class="errors.length > 0 ? 'is-invalid' : null">
                            <v-select
                              id="type-document"
                              v-model="formData.typeDocument"
                              :options="typeDocumentsOptions"
                              :reduce="val => val.id"
                              input-id="tax-regime"
                              label="type"
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="10" md="3" class="mb-lg-1">
                        <h6 class="mb-2">
                          Número de identificación:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="número de identificación"
                          rules="required"
                        >
                          <b-form-group label-for="identificationNumber" :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              id="identificationNumber"
                              input-id="invoice-data-client"
                              :clearable="false"
                              v-model="formData.identificationNumber"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="2" md="1" class="mb-lg-1">
                        <h6 class="mb-2">
                          DV:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="DV"
                          rules="required"
                        >
                          <b-form-group label-for="verificationDigit" :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              id="verificationDigit"
                              input-id="invoice-data-client"
                              :clearable="false"
                              v-model="formData.verificationDigit"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" md="8" class="mb-lg-1">
                        <h6 class="mb-2">
                          Nombre - Razon social:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="nombre - razon social"
                          rules="required"
                        >
                          <b-form-group label-for="name" :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              id="name"
                              input-id="invoice-data-client"
                              :clearable="false"
                              v-model="formData.name"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2">
                          Departamento:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="departamento"
                          rules="required"
                        >
                          <b-form-group label-for="department" :class="errors.length > 0 ? 'is-invalid' : null">
                            <v-select
                              id="department"
                              v-model="formData.department"
                              :options="departmentsOptions"
                              :reduce="val => val.id"
                              input-id="tax-regime"
                              label="name"
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2">
                          Municipio/ciudad:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="municipio/ciudad"
                          rules="required"
                        >
                          <b-form-group label-for="municipality" :class="errors.length > 0 ? 'is-invalid' : null">
                            <v-select
                              id="municipality"
                              v-model="formData.municipality"
                              :options="municipalitiesOptions"
                              :reduce="val => val.id"
                              input-id="tax-regime"
                              label="name"
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2">
                          Dirección de notificación:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="dirección de notificación"
                          rules="required"
                        >
                          <b-form-group label-for="address" :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              id="address"
                              input-id="invoice-data-client"
                              :clearable="false"
                              v-model="formData.address"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2">
                          Celular de contacto:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="celular de contacto"
                          rules="required"
                        >
                          <b-form-group label-for="phone" :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              id="phone"
                              input-id="invoice-data-client"
                              :clearable="false"
                              v-model="formData.phone"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2">
                          Correo electrónico:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="correo electrónico"
                          rules="required"
                        >
                          <b-form-group label-for="email" :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              id="email"
                              input-id="invoice-data-client"
                              :clearable="false"
                              v-model="formData.email"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2">
                          Confirme el correo electrónico:
                        </h6>
                        <validation-provider
                          #default="{ errors }"
                          name="confirme correo electrónico"
                          rules="required"
                        >
                          <b-form-group label-for="emailConfirm" :class="errors.length > 0 ? 'is-invalid' : null">
                            <b-form-input
                              :state="errors.length > 0 ? false : null" 
                              :class="errors.length > 0 ? 'is-invalid' : null"
                              id="emailConfirm"
                              input-id="invoice-data-client"
                              :clearable="false"
                              v-model="formData.emailConfirm"
                              class="form-control-merge"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2">
                          Contraseña:
                        </h6>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="contraseña"
                            vid="password"
                            rules="required"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid':null"
                            >
                              <b-form-input
                                id="password"
                                v-model="formData.password"
                                :state="errors.length > 0 ? false:null"
                                class="form-control-merge"
                                :type="passwordFieldType"
                                name="password"
                                placeholder="Contraseña"
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  class="cursor-pointer"
                                  :icon="passwordToggleIcon"
                                  @click="togglePasswordVisibility"
                                />
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2">
                          Confirmar contraseña:
                        </h6>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="confirma contraseña"
                            vid="passwordConfirm"
                            rules="required"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid':null"
                            >
                              <b-form-input
                                id="passwordConfirm"
                                v-model="formData.passwordConfirm"
                                :state="errors.length > 0 ? false:null"
                                class="form-control-merge"
                                :type="passwordFieldType"
                                name="password"
                                placeholder="Contraseña"
                              />
                              <b-input-group-append is-text>
                                <feather-icon
                                  class="cursor-pointer"
                                  :icon="passwordToggleIcon"
                                  @click="togglePasswordVisibility"
                                />
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="4" class="mb-lg-1">
                        <h6 class="mb-2" style="color: #fff;">
                          .
                        </h6>
                        <b-button
                          variant="primary"
                          class="mb-75"
                          block
                          @click="registerCreate"
                        >
                          Registrarse
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </validation-observer>
            </b-form>
          </b-col>
        </b-row>
      </section>
    </div>
  </section>
</template>

<script>

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es';
import vSelect from 'vue-select'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow, 
  BCol, 
  BLink, 
  BButton, 
  BForm, 
  BFormCheckbox, 
  BFormGroup, 
  BFormInput, 
  BInputGroup, 
  BInputGroupAppend, 
  BImg, 
  BCardTitle, 
  BCardText,
} from 'bootstrap-vue'
import { required, email, digits, integer } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      formData: {
        typePerson: null,
        taxRegime: null,
        typeDocument: null,
        identificationNumber: '',
        verificationDigit: '',
        name: '',
        department: null,
        municipality: null,
        address: '',
        phone: '',
        email: '',
        emailConfirm: '',
        password: '',
        passwordConfirm: '',
      },
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/logo/ecoopsos-logo.png'),
      imgLogo: require('@/assets/images/logo/ecoopsos-logo.png'),
      typePeopleOptions: [],
      taxRegimesOptions: [],
      typeDocumentsOptions: [],
      departmentsOptions: [],
      municipalitiesOptions: [],
      // validation
      required,
      email,
    }
  },
  directives: {
    Ripple,
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/ecoopsos-logo.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.initialize()
    this.getTypePeople()
    this.getTaxRegimes()
    this.getTypeDocuments()
    this.getDepartment()
  },
  watch: {
    'formData.department': {
      handler(val) {
        if (val) {
          this.getMunicipalities(val)
          this.formData.municipality = null
        }
      }
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register({
            username: this.username,
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(response.data.userData))
              this.$ability.update(response.data.userData.ability)
              this.$router.push('/')
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data.error)
            })
        }
      })
    },
    registerCreate() {
      this.$refs.formDataValidation.validate().then(success => {
        if (success) {
          this.$http.post('/register/create', this.formData).then((response) => {
            this.resetForm()
            this.$router.push('/login')
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Registro realizado con éxito`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Enviamos un correo de confirmación del registro`,
              },
            })
          })
          .catch(error => {
            const message = error.response.data.message
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error en los datos de registro`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          })
        }
      })
      .catch(error => {
        this.$refs.registerForm.setErrors(error.response.data.error)
      })
    },
    initialize() {
      localize('es', es)
    },
    getTypePeople() {
      this.$http.get('/register/type-people').then((response) => {
        this.typePeopleOptions = response.data.typePeople
      })
    },
    getTaxRegimes() {
      this.$http.get('/register/tax-regimes').then((response) => {
        this.taxRegimesOptions = response.data.taxRegimes
      })
    },
    getTypeDocuments() {
      this.$http.get('/register/type-documents').then((response) => {
        this.typeDocumentsOptions = response.data.typeDocuments
      })
    },
    getDepartment() {
      this.$http.get('/register/departments').then((response) => {
        this.departmentsOptions = response.data.departments
      })
    },
    getMunicipalities(department) {
      this.$http.get('/register/department/' + department).then((response) => {
        this.municipalitiesOptions = response.data.municipalities
      })
    },
    resetForm() {
      this.formData = {
        typePerson: null,
        taxRegime: null,
        typeDocument: null,
        identificationNumber: '',
        verificationDigit: '',
        name: '',
        department: null,
        municipality: null,
        address: '',
        phone: '',
        email: '',
        emailConfirm: '',
        password: '',
        passwordConfirm: '',
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
 .background-white {
  background: #fff;
 }
</style>
