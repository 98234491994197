var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"h-100 W-100 background-white"},[_c('div',{staticClass:"p-5",staticStyle:{"height":"100vh"}},[_c('section',{staticClass:"invoice-add-wrapper"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"12"}},[_c('b-form',[_c('validation-observer',{ref:"formDataValidation"},[_c('b-card',{staticClass:"invoice-preview-card",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"invoice-padding pt-3"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12 mb-2"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center pt-1"},[_c('b-img',{staticStyle:{"width":"50%"},attrs:{"fluid":"","src":_vm.sideImg,"alt":"Login V2"}})],1)])],1),_c('b-row',[_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Tipo de persona: ")]),_c('validation-provider',{attrs:{"name":"Tipo de persona","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"type-people"}},[_c('v-select',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"type-people","options":_vm.typePeopleOptions,"reduce":function (val) { return val.id; },"input-id":"proceeding-type","label":"type","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.typePerson),callback:function ($$v) {_vm.$set(_vm.formData, "typePerson", $$v)},expression:"formData.typePerson"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Regimen tributario: ")]),_c('validation-provider',{attrs:{"name":"regimen tributario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"tax-regime"}},[_c('v-select',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"tax-regime","options":_vm.taxRegimesOptions,"reduce":function (val) { return val.id; },"input-id":"tax-regime","label":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.taxRegime),callback:function ($$v) {_vm.$set(_vm.formData, "taxRegime", $$v)},expression:"formData.taxRegime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Tipo de identificación: ")]),_c('validation-provider',{attrs:{"name":"tipo de identificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"type-document"}},[_c('v-select',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"type-document","options":_vm.typeDocumentsOptions,"reduce":function (val) { return val.id; },"input-id":"tax-regime","label":"type","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.typeDocument),callback:function ($$v) {_vm.$set(_vm.formData, "typeDocument", $$v)},expression:"formData.typeDocument"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"10","md":"3"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Número de identificación: ")]),_c('validation-provider',{attrs:{"name":"número de identificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"identificationNumber"}},[_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"state":errors.length > 0 ? false : null,"id":"identificationNumber","input-id":"invoice-data-client","clearable":false},model:{value:(_vm.formData.identificationNumber),callback:function ($$v) {_vm.$set(_vm.formData, "identificationNumber", $$v)},expression:"formData.identificationNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"2","md":"1"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" DV: ")]),_c('validation-provider',{attrs:{"name":"DV","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"verificationDigit"}},[_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"state":errors.length > 0 ? false : null,"id":"verificationDigit","input-id":"invoice-data-client","clearable":false},model:{value:(_vm.formData.verificationDigit),callback:function ($$v) {_vm.$set(_vm.formData, "verificationDigit", $$v)},expression:"formData.verificationDigit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"8"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Nombre - Razon social: ")]),_c('validation-provider',{attrs:{"name":"nombre - razon social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"name"}},[_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"state":errors.length > 0 ? false : null,"id":"name","input-id":"invoice-data-client","clearable":false},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Departamento: ")]),_c('validation-provider',{attrs:{"name":"departamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"department"}},[_c('v-select',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"department","options":_vm.departmentsOptions,"reduce":function (val) { return val.id; },"input-id":"tax-regime","label":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.department),callback:function ($$v) {_vm.$set(_vm.formData, "department", $$v)},expression:"formData.department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Municipio/ciudad: ")]),_c('validation-provider',{attrs:{"name":"municipio/ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"municipality"}},[_c('v-select',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"municipality","options":_vm.municipalitiesOptions,"reduce":function (val) { return val.id; },"input-id":"tax-regime","label":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.municipality),callback:function ($$v) {_vm.$set(_vm.formData, "municipality", $$v)},expression:"formData.municipality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Dirección de notificación: ")]),_c('validation-provider',{attrs:{"name":"dirección de notificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"address"}},[_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"state":errors.length > 0 ? false : null,"id":"address","input-id":"invoice-data-client","clearable":false},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Celular de contacto: ")]),_c('validation-provider',{attrs:{"name":"celular de contacto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"phone"}},[_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"state":errors.length > 0 ? false : null,"id":"phone","input-id":"invoice-data-client","clearable":false},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Correo electrónico: ")]),_c('validation-provider',{attrs:{"name":"correo electrónico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"email"}},[_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"state":errors.length > 0 ? false : null,"id":"email","input-id":"invoice-data-client","clearable":false},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Confirme el correo electrónico: ")]),_c('validation-provider',{attrs:{"name":"confirme correo electrónico","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label-for":"emailConfirm"}},[_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"state":errors.length > 0 ? false : null,"id":"emailConfirm","input-id":"invoice-data-client","clearable":false},model:{value:(_vm.formData.emailConfirm),callback:function ($$v) {_vm.$set(_vm.formData, "emailConfirm", $$v)},expression:"formData.emailConfirm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Contraseña: ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"contraseña","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"password","placeholder":"Contraseña"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2"},[_vm._v(" Confirmar contraseña: ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"confirma contraseña","vid":"passwordConfirm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"passwordConfirm","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"password","placeholder":"Contraseña"},model:{value:(_vm.formData.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.formData, "passwordConfirm", $$v)},expression:"formData.passwordConfirm"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-lg-1",attrs:{"cols":"12","md":"4"}},[_c('h6',{staticClass:"mb-2",staticStyle:{"color":"#fff"}},[_vm._v(" . ")]),_c('b-button',{staticClass:"mb-75",attrs:{"variant":"primary","block":""},on:{"click":_vm.registerCreate}},[_vm._v(" Registrarse ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }